html {
	scroll-padding-top: 8rem; /* height of sticky header */
}
html,
body {
	height: 100%;
}
body {
	font-display: auto !important;
	margin: 0;
	font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.not-selectable,
.card,
picture,
img {
	user-select: none;
}

svg {
	vertical-align: text-bottom !important;
}

.modal-lg {
	max-width: 760px !important;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
	z-index: 1500 !important;
}

.pac-container {
	z-index: 1500 !important;
}

.ngx-mat-tel-input-container {
	display: flex !important;
	& > button {
		position: relative !important;
	}
}
